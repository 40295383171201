exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-category-markdown-remark-frontmatter-category-js": () => import("./../../../src/pages/blog/category/{MarkdownRemark.frontmatter__category}.js" /* webpackChunkName: "component---src-pages-blog-category-markdown-remark-frontmatter-category-js" */),
  "component---src-pages-blog-category-markdown-remark-frontmatter-category-markdown-remark-frontmatter-blog-category-js": () => import("./../../../src/pages/blog/category/{MarkdownRemark.frontmatter__category}/{MarkdownRemark.frontmatter__blogCategory}.js" /* webpackChunkName: "component---src-pages-blog-category-markdown-remark-frontmatter-category-markdown-remark-frontmatter-blog-category-js" */),
  "component---src-pages-blog-markdown-remark-frontmatter-category-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/blog/{MarkdownRemark.frontmatter__category}/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-category-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-build-enterprise-js": () => import("./../../../src/pages/build/enterprise.js" /* webpackChunkName: "component---src-pages-build-enterprise-js" */),
  "component---src-pages-build-index-js": () => import("./../../../src/pages/build/index.js" /* webpackChunkName: "component---src-pages-build-index-js" */),
  "component---src-pages-build-individual-js": () => import("./../../../src/pages/build/individual.js" /* webpackChunkName: "component---src-pages-build-individual-js" */),
  "component---src-pages-choose-js": () => import("./../../../src/pages/choose.js" /* webpackChunkName: "component---src-pages-choose-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-ecosystem-js": () => import("./../../../src/pages/ecosystem.js" /* webpackChunkName: "component---src-pages-ecosystem-js" */),
  "component---src-pages-enterprise-js": () => import("./../../../src/pages/enterprise.js" /* webpackChunkName: "component---src-pages-enterprise-js" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-execution-dashboard-dashboard-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-execution/dashboard/dashboard.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-execution-dashboard-dashboard-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-execution-live-reports-pnlbook-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-execution/live-reports/pnlbook.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-execution-live-reports-pnlbook-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-execution-live-reports-tradebook-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-execution/live-reports/tradebook.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-execution-live-reports-tradebook-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-execution-portfolio-live-trading-virtual-trading-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-execution/portfolio/live-trading-virtual-trading.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-execution-portfolio-live-trading-virtual-trading-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-odyssey-introduction-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/odyssey/introduction.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-odyssey-introduction-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-odyssey-strategy-details-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/odyssey/strategy-details.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-odyssey-strategy-details-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-copilot-introduction-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix-copilot/introduction.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-copilot-introduction-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-copilot-prompt-libary-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix-copilot/prompt-libary.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-copilot-prompt-libary-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-getting-started-introduction-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/getting-started/introduction.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-getting-started-introduction-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-getting-started-phoenix-support-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/getting-started/phoenix-support.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-getting-started-phoenix-support-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-getting-started-strategy-development-guide-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/getting-started/strategy-development-guide.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-getting-started-strategy-development-guide-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-phoenix-web-analytics-code-new-strategies-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/phoenix-web/analytics/code-new-strategies.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-phoenix-web-analytics-code-new-strategies-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-phoenix-web-analytics-code-ready-templates-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/phoenix-web/analytics/code-ready-templates.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-phoenix-web-analytics-code-ready-templates-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-phoenix-web-analytics-code-using-ai-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/phoenix-web/analytics/code-using-ai.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-phoenix-web-analytics-code-using-ai-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-phoenix-web-analytics-configure-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/phoenix-web/analytics/configure.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-phoenix-web-analytics-configure-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-phoenix-web-analytics-probuild-support-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/phoenix-web/analytics/probuild-support.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-phoenix-web-analytics-probuild-support-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-phoenix-web-understanding-phoenix-web-analytics-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/phoenix-web/understanding-phoenix-web/analytics.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-phoenix-web-understanding-phoenix-web-analytics-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-phoenix-web-understanding-phoenix-web-introduction-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/phoenix-web/understanding-phoenix-web/introduction.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-phoenix-web-understanding-phoenix-web-introduction-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-phoenix-web-understanding-phoenix-web-select-your-strategy-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/phoenix-web/understanding-phoenix-web/select-your-strategy.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-phoenix-web-understanding-phoenix-web-select-your-strategy-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-general-strategy-general-strategy-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/general-strategy/general-strategy.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-general-strategy-general-strategy-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-general-strategy-structure-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/general-strategy/structure.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-general-strategy-structure-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-option-strategies-option-strategies-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/option-strategies/option-strategies.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-option-strategies-option-strategies-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-option-strategies-options-bear-call-ladder-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/option-strategies/options_bear_call_ladder.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-option-strategies-options-bear-call-ladder-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-option-strategies-options-bear-put-ladder-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/option-strategies/options_bear_put_ladder.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-option-strategies-options-bear-put-ladder-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-option-strategies-options-bull-call-ladder-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/option-strategies/options_bull_call_ladder.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-option-strategies-options-bull-call-ladder-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-option-strategies-options-bull-put-ladder-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/option-strategies/options_bull_put_ladder.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-option-strategies-options-bull-put-ladder-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-option-strategies-options-long-iron-butterfly-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/option-strategies/options_long_iron_butterfly.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-option-strategies-options-long-iron-butterfly-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-option-strategies-options-straddle-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/option-strategies/options_straddle.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-option-strategies-options-straddle-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-option-strategies-options-strangle-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/option-strategies/options_strangle.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-option-strategies-options-strangle-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-aroon-crossover-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/regular-strategies/aroon-crossover.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-aroon-crossover-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-bollinger-bands-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/regular-strategies/bollinger-bands.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-bollinger-bands-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-ema-crossover-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/regular-strategies/ema-crossover.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-ema-crossover-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-inverse-ema-crossover-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/regular-strategies/inverse-ema-crossover.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-inverse-ema-crossover-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-macd-crossover-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/regular-strategies/macd-crossover.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-macd-crossover-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-mean-reversion-bollinger-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/regular-strategies/mean-reversion-bollinger.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-mean-reversion-bollinger-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-regular-structure-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/regular-strategies/regular-structure.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-regular-structure-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-reverse-rsi-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/regular-strategies/reverse-rsi.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-reverse-rsi-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-rsi-macd-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/regular-strategies/rsi-macd.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-rsi-macd-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-stochastic-crossover-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/regular-strategies/stochastic-crossover.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-stochastic-crossover-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-volatility-trend-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/regular-strategies/volatility-trend.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-volatility-trend-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-vwap-crossover-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/phoenix/strategy/regular-strategies/vwap-crossover.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-phoenix-strategy-regular-strategies-vwap-crossover-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-pyalgotrad-brokerage-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/pyalgotrad/brokerage.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-pyalgotrad-brokerage-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-pyalgotrad-introduction-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/pyalgotrad/introduction.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-pyalgotrad-introduction-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-pyalgotrad-prerequisites-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/pyalgotrad/prerequisites.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-pyalgotrad-prerequisites-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-pyalgotrad-pyalgotrading-analytics-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/pyalgotrad/pyalgotrading_analytics.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-pyalgotrad-pyalgotrading-analytics-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-pyalgotrad-select-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/pyalgotrad/select.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-pyalgotrad-select-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-pyalgotrad-slippage-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/pyalgotrad/slippage.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-pyalgotrad-slippage-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-pyalgotrad-testing-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/pyalgotrad/testing.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-pyalgotrad-testing-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-pyalgotrad-upload-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/algo-products/pyalgotrad/upload.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-algo-products-pyalgotrad-upload-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-broker-information-indian-brokers-angel-broking-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/broker-information/indian-brokers/angel-broking.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-broker-information-indian-brokers-angel-broking-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-broker-information-indian-brokers-dhan-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/broker-information/indian-brokers/dhan.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-broker-information-indian-brokers-dhan-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-broker-information-indian-brokers-enrich-money-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/broker-information/indian-brokers/enrich-money.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-broker-information-indian-brokers-enrich-money-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-broker-information-indian-brokers-fyers-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/broker-information/indian-brokers/fyers.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-broker-information-indian-brokers-fyers-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-broker-information-indian-brokers-msfl-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/broker-information/indian-brokers/msfl.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-broker-information-indian-brokers-msfl-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-broker-information-indian-brokers-nuvama-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/broker-information/indian-brokers/nuvama.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-broker-information-indian-brokers-nuvama-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-broker-information-indian-brokers-sharekhan-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/broker-information/indian-brokers/sharekhan.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-broker-information-indian-brokers-sharekhan-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-broker-information-indian-brokers-upstox-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/broker-information/indian-brokers/upstox.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-broker-information-indian-brokers-upstox-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-broker-information-us-brokers-alpaca-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/broker-information/us-brokers/alpaca.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-broker-information-us-brokers-alpaca-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-broker-information-us-brokers-interactive-brokers-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/broker-information/us-brokers/interactive-brokers.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-broker-information-us-brokers-interactive-brokers-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-features-and-resources-algobuddy-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/features-and-resources/algobuddy.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-features-and-resources-algobuddy-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-features-and-resources-pnltracker-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/features-and-resources/pnltracker.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-features-and-resources-pnltracker-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-features-and-resources-tweak-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/features-and-resources/tweak.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-features-and-resources-tweak-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-getting-started-how-to-begin-charts-overview-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/getting-started/how-to-begin/charts-overview.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-getting-started-how-to-begin-charts-overview-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-getting-started-how-to-begin-connecting-broker-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/getting-started/how-to-begin/connecting-broker.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-getting-started-how-to-begin-connecting-broker-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-getting-started-how-to-begin-start-a-strategy-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/getting-started/how-to-begin/start-a-strategy.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-getting-started-how-to-begin-start-a-strategy-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-getting-started-introduction-to-algobulls-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/getting-started/introduction-to-algobulls.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-getting-started-introduction-to-algobulls-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-getting-started-login-registration-login-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/getting-started/login-registration/login.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-getting-started-login-registration-login-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-getting-started-login-registration-registration-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/getting-started/login-registration/registration.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-getting-started-login-registration-registration-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-setup-settings-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/setup/settings.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-setup-settings-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-subscriptions-my-plans-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/subscriptions/my-plans.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-subscriptions-my-plans-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-subscriptions-pricing-plans-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/subscriptions/pricing-plans.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-subscriptions-pricing-plans-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-troubleshooting-developers-option-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/troubleshooting/developers-option.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-troubleshooting-developers-option-mdx" */),
  "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-troubleshooting-faq-mdx": () => import("./../../../src/pages/help/article/{mdx.frontmatter__slug}.js?__contentFilePath=/home/algobulls/frontend/algobulls_website_landing_pages/src/help-content/troubleshooting/faq.mdx" /* webpackChunkName: "component---src-pages-help-article-mdx-frontmatter-slug-js-content-file-path-src-help-content-troubleshooting-faq-mdx" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-help-search-result-js": () => import("./../../../src/pages/help/SearchResult.js" /* webpackChunkName: "component---src-pages-help-search-result-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-odyssey-js": () => import("./../../../src/pages/odyssey.js" /* webpackChunkName: "component---src-pages-odyssey-js" */),
  "component---src-pages-phoenix-copilot-js": () => import("./../../../src/pages/phoenix-copilot.js" /* webpackChunkName: "component---src-pages-phoenix-copilot-js" */),
  "component---src-pages-phoenix-js": () => import("./../../../src/pages/phoenix.js" /* webpackChunkName: "component---src-pages-phoenix-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-probuild-js": () => import("./../../../src/pages/probuild.js" /* webpackChunkName: "component---src-pages-probuild-js" */),
  "component---src-pages-python-build-js": () => import("./../../../src/pages/python-build.js" /* webpackChunkName: "component---src-pages-python-build-js" */),
  "component---src-pages-quant-quest-js": () => import("./../../../src/pages/quant-quest.js" /* webpackChunkName: "component---src-pages-quant-quest-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/articleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */)
}

